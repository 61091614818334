import React from "react"
import { ResponsivePie } from '@nivo/pie'


export default function engagementSentiment({data}) {
  const items = [
    {
      "id": "negative",
      "label": "Negative",
      "value": data.negative,
      "color": "hsl(0, 78%, 64%)"
    },
    {
      "id": "neutral",
      "label": "Neutral",
      "value": data.neutral,
      "color": "hsl(0, 0%, 60%)"
    },
    {
      "id": "positive",
      "label": "Positive",
      "value": data.positive,
      "color": "hsl(158, 67%, 48%)"
    },
  ]
  
  return (
    <div className="card rounded p-4">
      <div className="font-medium">Engagement Sentiment</div>
      <div className="h-[200px]">
        <ResponsivePie
          data={items}
          margin={{ top: 40, right: 100, bottom: 40, left: 100 }}
          startAngle={-90}
          endAngle={90}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{
            from: 'color',
            modifiers: [
              [
                'darker',
                0.2
              ]
            ]
          }}
          colors={({ id }) => items.find((item) => item.id === id)?.color || "#ccc"}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: 'color',
            modifiers: [
              [
                'darker',
                2
              ]
            ]
          }}
          legends={[
            {
              anchor: 'bottom',
              direction: 'row',
              justify: false,
              translateX: 25,
              translateY: 20,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: '#999',
              itemDirection: 'left-to-right',
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: 'circle',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemTextColor: '#000'
                  }
                }
              ]
            }
          ]}
        />
      </div>
    </div>
  )
}