import React from "react"
import { ResponsiveBar } from '@nivo/bar'
import colors from 'tailwindcss/colors'


export default function openReviews({data}) {
  const items = Object.entries(data).map(([key, value]) => ({
    time: key,
    value: value,
  }));

  return (
    <div className="card rounded p-4 mt-4">
      <div className="font-medium">Open Reviews</div>
      <div className="h-[200px]">
        <ResponsiveBar
          data={items}
          enableLabel={false}
          keys={["value"]}
          indexBy="time"
          margin={{ top: 40, right: 10, bottom: 50, left: 50 }}
          padding={0.3}
          colors={() => colors.gray[600]}
          borderRadius={2}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Time (hours)",
            legendPosition: "middle",
            legendOffset: 40,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            tickValues: 1
          }}
          enableGridY={false}
          animate={true}
        />
      </div>
    </div>
  )
}