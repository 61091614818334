import React from "react"
import { ResponsivePie } from '@nivo/pie'


export default function engagementSentiment({data}) {
  const total = data.positiveCount + data.neutralCount + data.negativeCount
  const percentage = value => ((value / total) * 100).toFixed(1)
  const items = [
    {
      "id": "positive",
      "label": "Positive",
      "value": percentage(data.positiveCount),
      "color": "hsl(158, 67%, 48%)"
    },
    {
      "id": "neutral",
      "label": "Neutral",
      "value": percentage(data.neutralCount),
      "color": "hsl(0, 0%, 60%)"
    },
    {
      "id": "negative",
      "label": "Negative",
      "value": percentage(data.negativeCount),
      "color": "hsl(0, 78%, 64%)"
    },
  ]
  
  return (
    <div className="card rounded p-4">
      <div className="font-medium mb-4">Review Sentiment</div>
      <div className="flex items-center">
        <div className="grid grid-cols divide-y divide-gray-400 mr-4">
          { items.map(item => (
            <div key={item.id} className="flex justify-center flex-col text-center p-2">
              <div className="font-medium" style={{color: item.color}}>{item.value}%</div>
              <div className="text-xs">{item.label}</div>
            </div>
          ))}
        </div>
        <div className="w-full">
          <div className="w-[200px] h-[200px]">
            <ResponsivePie
              data={items}
              enableArcLinkLabels={false}
              margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
              innerRadius={0}
              padAngle={0.7}
              colors={{ datum: "data.color" }}
              borderWidth={1}
              borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
              enableRadialLabels={false}
              enableSliceLabels={false}
              enableArcLabels={false}
            />
          </div>
        </div>
      </div>
      
    </div>
  )
}