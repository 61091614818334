import React, { useRef, useContext, useState } from "react"
import Rates from './Rates'
import { GlobalContext } from "../../context/GlobalContext"

export default function ILSRates() {
  const { currentUserData } = useContext(GlobalContext)
  const showActions = currentUserData?.currentUser?.email?.endsWith('@brynson.com')

  return (
    <>
      <Rates />
    </>

  )
}
