import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
import BrynsonSquareButton from "../../shared/BrynsonSquareButton"
import Modal from "../../shared/Modal"
import DesktopH5 from "../../shared/DesktopH5"
import InputField from "../../shared/InputField"
import CurrencyInput from "../../shared/CurrencyInput"
import TabContext from "./TabContext"
import SelectField from "../../shared/SelectField"

import { PropertiesDirectoryContext } from "../../context/PropertiesDirectoryContext"

const groupedFields = [
  {
    accessor: "propertyDirectoryId",
    title: "Property Directory",
    type: "int",
  },
  {
    accessor: "propertyId",
    title: "Property",
    type: "int",
  },
  {
    accessor: "brynsonIlsManagementFee",
    title: "Brynson ILS Mgmt Fee",
    type: "currency",
  },
  {
    accessor: "ownershipGroup",
    title: "Ownership Group/Type",
    type: "string",
  },
  {
    accessor: "region",
    title: "Region",
    type: "string",
  },
  {
    type: "group",
    title: "CoStar",
    accessor: "CoStarGroup",
    fields: [
      {
        accessor: "currentCostarPackage",
        title: "Package",
        type: "string",
      },
      {
        accessor: "brynsonCostarRate",
        title: "Brynson CoStar Rate",
        type: "currency",
      },
      {
        accessor: "currentCostarRate",
        title: "CoStar Rate",
        type: "currency",
      },
      {
        accessor: "costarPropertyTaxes",
        title: "State Taxes (if applicable)",
        type: "currency",
      },
      {
        accessor: "costarChanges",
        title: "Changes",
        type: "string",
      },
    ]
  },
  {
    type: "group",
    title: "Apartmentlist",
    accessor: "ApartmentlistGroup",
    fields: [
      {
        accessor: "apartmentListPackage",
        title: "Package",
        type: "string",
      },
      {
        accessor: "brynsonApartmentListRate",
        title: "Rate",
        type: "currency",
      },
      {
        accessor: "apartmentListPplFee",
        title: "PPL Fee",
        type: "currency",
      },
      {
        accessor: "apartmentListPlatformFee",
        title: "Apartment List Platform Fee",
        type: "currency",
      },
      {
        accessor: "apartmentListPropertyTaxes",
        title: "State Taxes (if applicable)",
        type: "currency",
      },
      {
        accessor: "apartmentListChanges",
        title: "Changes",
        type: "string",
      },
      {
        accessor: "apartmentListLeases",
        title: "Leases",
        type: "integer",
      },
      {
        accessor: "apartmentListPplTotal",
        title: "PPL Total",
        type: "currency",
      },
    ]
  },
  {
    type: "group",
    title: "Rent.com",
    accessor: "RentComGroup",
    fields: [
      {
        accessor: "currentRentComPackage",
        title: "Package",
        type: "string",
      },
      {
        accessor: "brynsonRentRate",
        title: "Rate",
        type: "currency",
      },
      {
        accessor: "rentPropertyTaxes",
        title: "State Taxes (if applicable)",
        type: "currency",
      },
      {
        accessor: "rentComChanges",
        title: "Changes",
        type: "string",
      },
    ]
  },
  {
    type: "group",
    title: "Zumper",
    accessor: "ZumperGroup",
    fields: [
      {
        accessor: "currentZumperPackage",
        title: "Package",
        type: "string",
      },
      {
        accessor: "brynsonZumperRate",
        title: "Rate",
        type: "currency",
      },
      {
        accessor: "zumperPropertyTaxes",
        title: "State Taxes (if applicable)",
        type: "currency",
      },
      {
        accessor: "zumperChanges",
        title: "Changes",
        type: "string",
      },
    ]
  },
  {
    type: "group",
    title: "Rentable",
    accessor: "RentableGroup",
    fields: [
      {
        accessor: "currentRentablePackage",
        title: "Package",
        type: "string",
      },
      {
        accessor: "brynsonRentableRate",
        title: "Rate",
        type: "currency",
      },
      {
        accessor: "rentablePropertyTaxes",
        title: "State Taxes (if applicable)",
        type: "currency",
      },
      {
        accessor: "rentableChanges",
        title: "Changes",
        type: "string",
      },
    ]
  },
  {
    type: "group",
    title: "3DApartments",
    accessor: "3DApartmentsGroup",
    fields: [
      {
        accessor: "current3dApartmentsPackage",
        title: "Package",
        type: "string",
      },
      {
        accessor: "brynson3dApartmentsRate",
        title: "Rate",
        type: "currency",
      }
    ]
  },
  {
    type: "group",
    title: "Realtor",
    accessor: "realtorGroup",
    fields: [
      {
        accessor: "currentRealtorPackage",
        title: "Package",
        type: "string",
      },
      {
        accessor: "brynsonRealtorRate",
        title: "Rate",
        type: "currency",
      },
      {
        accessor: "realtorPropertyTaxes",
        title: "State Taxes (if applicable)",
        type: "currency",
      },
      {
        accessor: "realtorChanges",
        title: "Changes",
        type: "string",
      },
    ]
  },
  {
    type: "group",
    title: "Zillow",
    accessor: "zillowGroup",
    fields: [
      {
        accessor: "currentZillowPackage",
        title: "Package",
        type: "string",
      },
      {
        accessor: "brynsonZillowRate",
        title: "Rate",
        type: "currency",
      },
      {
        accessor: "zillowPplFee",
        title: "PPL Rate",
        type: "currency",
      },
      {
        accessor: "zillowPropertyTaxes",
        title: "State Taxes (if applicable)",
        type: "currency",
      },
      {
        accessor: "zillowChanges",
        title: "Zillow Changes",
        type: "string",
      },
      {
        accessor: "zillowLeases",
        title: "Leases",
        type: "integer",
      },
      {
        accessor: "zillowPplTotal",
        title: "PPL Total",
        type: "currency",
      },
    ]
  },
  {
    type: "group",
    title: "RentSocial",
    accessor: "rentGroup",
    fields: [
      {
        accessor: "currentRentSocialPackage",
        title: "Package",
        type: "string",
      },
      {
        accessor: "brynsonRentSocialRate",
        title: "Rate",
        type: "currency",
      },
      {
        accessor: "rentSocialPropertyTaxes",
        title: "State Taxes (if applicable)",
        type: "currency",
      }
    ]
  },
];

const getFields = () => {
  const fields = []
  groupedFields.forEach(field => {
    if (field.type !== 'group') {
      fields.push(field);
    } else {
      field.fields.forEach(subField => {
        fields.push(subField);
      });
    }
  });
  return fields;
}

export default function PropertyInfoForm({ item = {}, OpenModalComponent = null}) {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [currentInput, setCurrentInput] = useState(item)
  const { updatePropertyDirectoryInfo, createPropertyDirectoryInfo} = useContext(PropertiesDirectoryContext)
  const [propertiesDirectoriesOptions, setPropertiesDirectoriesOptions] = useState([])
  const [propertiesOptions, setPropertiesOptions] = useState([])
  const fields = getFields()

  const { propertiesDirectoryData, propertiesData } = useContext(TabContext);

  useEffect(() => {
    if (propertiesDirectoryData) {
      const options = propertiesDirectoryData.propertyDirectories.sort((a, b) => {
        const dateA = new Date(`${a.month}-01-${a.year}`);
        const dateB = new Date(`${b.month}-01-${b.year}`);
        return dateB - dateA;
      }).map((i) => ({ value: i.id, label: `${i.month}-${i.year}` }))
      setPropertiesDirectoriesOptions(options)
    }
    
  }, [propertiesDirectoryData]);

  useEffect(() => {
    if (propertiesData) {
      const options = propertiesData?.properties.map( x => ({ value: x.id, label: x.name })) || []
      setPropertiesOptions(options)
    }
    
  }, [propertiesData]);
  const title = item?.id ? 'Edit Property Info' : 'Add Property Info'

  const DefaultOpenModalComponent = ({ onClick, label }) => (
    <a className="font-medium text-bblue underline" onClick={onClick}>{label}</a>
  );
  const OpenComponent = OpenModalComponent || DefaultOpenModalComponent;

  const addValue = (field, value) => {
    const current = {}
    current[field] = value
    setCurrentInput({ ...currentInput, ...current })
  }

  var getFloatValue = (value) => {
    var numericValue = value
      .replace("$", "")
      .replace(/,/g, "")
      .trim();

    return parseFloat(numericValue) || 0;
  };

  const getPayload = () => {
    const current = { id: item.id }
    fields.forEach(field => {
      let value = currentInput[field.accessor] || null
      if (value && typeof value === 'string') {
        if (field.type === 'currency') { value = getFloatValue(value) }
        if (field.type === 'integer' || field.type === 'int') { value = parseInt(value) }
      }
      current[field.accessor] = value
    })

    return current
  }

  const mountField = (field) => {
    return (
      <React.Fragment key={`property-info-field-${field.accessor}`}>
        {field.type === "string" &&
          <div className="mb-2">
            <label htmlFor={field.accessor}>{field.title}</label>
            <InputField
              id={field.accessor}
              placeholder={field.title}
              defaultValue={item[field.accessor]}
              onChange={(input) => {
                addValue(field.accessor, input.target.value)
              }}
            />
          </div>
        }
        {field.type === "currency" &&
          <div className="mb-2">
            <label htmlFor={field.accessor}>{field.title}</label>
            <CurrencyInput
              id={field.accessor}
              placeholder={field.title}
              defaultValue={item[field.accessor]}
              onChange={(input) => {
                addValue(field.accessor, input.target.value)
              }}
            />
          </div>
        }
        {field.type === "integer" &&
          <div className="mb-2">
            <label htmlFor={field.accessor}>{field.title}</label>
            <InputField
              type='number'
              id={field.accessor}
              placeholder={field.title}
              defaultValue={item[field.accessor]}
              onChange={(input) => {
                addValue(field.accessor, input.target.value)
              }}
            />
          </div>
        }
        {field.accessor === "propertyDirectoryId" &&
          <div className="mb-2">
            <label htmlFor={field.accessor}>{field.title}</label>
            <SelectField
              options={propertiesDirectoriesOptions}
              id={field.accessor}
              isDisabled={item.propertyDirectoryId}
              defaultValue={item.propertyDirectoryId ? propertiesDirectoriesOptions.find(x => x.value == item.propertyDirectoryId) : null }
              onChange={(value) => {
                addValue(field.accessor, value)
              }}
            />
          </div>
        }
        {field.accessor === "propertyId" &&
          <div className="mb-2">
            <label htmlFor={field.accessor}>{field.title}</label>
            <SelectField
              options={propertiesOptions}
              id={field.accessor}
              isDisabled={item.propertyId}
              defaultValue={item.propertyId ? propertiesOptions.find(x => x.value == item.propertyId) : null }
              onChange={(value) => {
                addValue(field.accessor, value)
              }}
            />
          </div>
        }
        {field.type === "group" &&
          <React.Fragment key={`property-info-group-${field.title}`}>
            <h4 className="my-4 grow text-md font-bold">{field.title}</h4>
            {field.fields.map((subfield) => (
              mountField(subfield)
            ))}
          </React.Fragment>
        }
      </React.Fragment>
    )
  }

  const save = () => {
    setLoading(true)
    const payload = getPayload()


    if(payload.id) {
      updatePropertyDirectoryInfo(payload).then((result) => {
        if (result?.error || result?.data?.editProperty?.errors) {
          setError(`Error updating property directory info: ${result?.error || result?.data?.editProperty?.errors}`)
        } else {
          setOpen(false)
        }
        setLoading(false)
      })
    } else {
      createPropertyDirectoryInfo(payload).then((result) => {
        if (result?.error || result?.data?.editProperty?.errors) {
          setError(`Error updating property directory info: ${result?.error || result?.data?.editProperty?.errors}`)
        } else {
          setOpen(false)
        }
        setLoading(false)
      })
    }
  }

  return (
    <>{propertiesData &&
      <>
        <OpenComponent onClick={() => setOpen(true)} label={item?.name} />
        <Modal open={open} key='property-info-form-model' closeModal={() => setOpen(false)}
          header={
            <>
              <DesktopH5 content={title} />
            </>
          }
          footer={
            <>
              <BrynsonSquareButton
                type="tertiary"
                onClick={() => setOpen(false)}
                label="Cancel"
              />
              <BrynsonSquareButton
                type="primary"
                testId="add-new-property-button"
                loading={loading}
                onClick={save}
                label="Save"
              />
            </>
          }>
          <div className="mb-2 mr-2">
            {error && <p className="text-red-600">{error}</p>}
            {groupedFields.map((field) => (
              mountField(field)
            ))}
          </div>
        </Modal>
      </>
      }
    </>
  )
}