import React from "react"
import numeral from "numeral"
import { FaFacebookF, FaYelp, FaGoogle } from "react-icons/fa";
import { MdApartment } from "react-icons/md";
import { GoGlobe } from "react-icons/go";


export default function reviewsByNetwork({data}) {
  console.log('data', data)
  const percentage = value => ((value / total) * 100).toFixed(1)
  const abbreviateNumber = number => numeral(number).format('0,00a')
  const total = Object.entries(data)
    .filter(([_, value]) => typeof value === 'number')
    .reduce((sum, [_, value]) => sum + value, 0);


  const items = [
    { label: "Google", value: data.gmb, percentage: percentage(data.gmb), icon: <FaGoogle/> },
    { label: "Apartments", value: data.aptratings, percentage: percentage(data.aptratings), icon: <MdApartment/> },
    { label: "Facebook", value: data.facebook, percentage: percentage(data.facebook), icon: <FaFacebookF /> },
    { label: "Yelp", value: data.yelp, percentage: percentage(data.yelp), icon: <FaYelp /> },
    { label: "Other Sites", value: (data.aptguide + data.apartments), percentage: percentage(data.aptguide + data.apartments), icon: <GoGlobe/> },
  ].sort(x => x.value);
  
  return (
    <div className="card rounded p-4">
      <div className="font-medium mb-4">Reviews by Network</div>
      <div className="h-[200px]">
        {items.map((item, index) => (
          <div key={index} className="flex items-center space-x-3">
            <span className="text-lg" title={item.label}>{item.icon}</span>
            <span className="w-5 text-right font-medium" title={item.value}>{abbreviateNumber(item.value)}</span>
            <div className="w-full bg-gray-300 h-4 rounded relative">
              <div
                className="absolute top-0 left-0 h-full bg-gray-600 rounded"
                style={{ width: `${item.percentage}%` }}
              >
                <span
                  className="absolute top-2 left-6 transform -translate-x-1/2 -translate-y-1/2 text-xs font-semibold text-white text-xs"
                >
                  {item.percentage}%
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}