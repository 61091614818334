import React from "react"
import usStates from "../../shared/usStatesTopo.json"
import statesMap from "../../shared/statesMap"
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from 'react-simple-maps';

export default function engagementActivity({data}) {
  const engagements = data.map(x => x.engagements)
  const minVal = Math.min(...engagements);
  const maxVal = Math.max(...engagements);

  const getRadius = (num) => 4 + ((num - minVal) / (maxVal - minVal)) * (20 - 4);

  const dataMap = {};
  return (
    <div className="card rounded p-4">
      <div className="font-medium">Engagement Activity</div>
      <div className="flex justify-center">
        <div className="w-[600px]">
          <ComposableMap projection="geoAlbersUsa">
            <Geographies geography={usStates}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  const stateName = geo.properties.name;
                  const stateAbbr = Object.keys(statesMap).find(
                    (key) => statesMap[key] === stateName
                  ) || stateName;
                  const datum = dataMap[stateName] || dataMap[stateAbbr];
                  const value = datum ? datum[valueKey] : 0;
                  const fillColor = value > 0 ? colorScale(colorScaleFunc(value)) : '#EEE';

                  return (
                    <React.Fragment key={geo.rsmKey}>
                      <Geography
                        geography={geo}
                        fill={fillColor}
                        stroke="#FFF"
                        strokeWidth={0.5}
                        style={{
                          default: { outline: 'none' },
                          pressed: { outline: 'none' },
                        }}
                      />
                      {
                        data.map((item) => (
                          <Marker key={item.id} coordinates={[parseFloat(item.long), parseFloat(item.lat)]}>
                            <title>{item.engagements}</title>
                            <circle r={getRadius(item.engagements)} fill="#F53" />
                          </Marker>
                        ))
                      }
                    </React.Fragment>
                  )
                })
              }
            </Geographies>
          </ComposableMap>
        </div>
      </div>
      
    </div>
    
  )
}