import React, { useMemo, useState, useContext, useEffect, createContext } from "react"

import { GlobalContext } from "../../context/GlobalContext"
import PropertiesDirectoryProvider from "../../context/PropertiesDirectoryContext"
import Loading from "../../shared/Loading"
import Selectbox from "../../shared/Selectbox"
import BrynsonTabs from "../../shared/BrynsonTabs"

import PropertiesTable from "./PropertiesTable"
import SummaryTab from "./SummaryTab"
import TabContext from "./TabContext"

export default function PropertyDirectories() {
  const [activeTab, setActiveTab] = useState("summary")
  const [selectedPropertyDirectory, setSelectedPropertyDirectory] = useState()

  const handleTabSelected = (index) => {
    const tabNames = ['summary', 'overview', 'historic'];
    if (window.Intercom) {
      window.Intercom('trackEvent', 'property_directory_tab_viewed', {
        tab: tabNames[index],
        location: window.location.pathname,
        timestamp: new Date().toISOString()
      });
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab)
  }

  const { propertiesDirectoryData: propertiesDirectoryData, propertiesDirectoryReload: reexecutePropertiesDirectoryQuery, currentCompanyData: currentCompany, propertiesData: propertiesData, propertyReload: reexecutePropertiesQuery  } = useContext(GlobalContext)

  useEffect(() => {
    reexecutePropertiesDirectoryQuery();
  }, [reexecutePropertiesDirectoryQuery]);

  useEffect(() => {
    reexecutePropertiesQuery();
  }, [reexecutePropertiesQuery]);

  useEffect(() => {
    reexecutePropertiesQuery();
  }, [reexecutePropertiesQuery]);

  const currentPropertyDirectory = useMemo(() => {
    const propertyDirectories = propertiesDirectoryData?.propertyDirectories || []
    return propertyDirectories.find((x) => x.current)
  }, [propertiesDirectoryData])

  const archivedPropertyDirectories = useMemo(() => {
    const propertyDirectories = propertiesDirectoryData?.propertyDirectories || []
    return propertyDirectories.filter((x) => !x.current)
  }, [propertiesDirectoryData])


  return (
    <PropertiesDirectoryProvider>
      <TabContext.Provider value={{ propertiesDirectoryData, propertiesData }}>
        <div className="p-8">
          <BrynsonTabs
            tabs={[
              {
                label: "Summary",
                icon: null
              },
              {
                label: "Overview",
                icon: null
              },
              {
                label: "Historic",
                icon: null
              }
            ]}
            className=""
            onSelected={handleTabSelected}
          >

            <div className="flex h-[75dvh] flex-col">
              <SummaryTab key="summary"></SummaryTab>
            </div>

            <div className="flex h-[75dvh] flex-col">
              <PropertiesTable
                key={activeTab}
                properties={currentPropertyDirectory?.propertyDirectoryInfo}
                propertyDirectoryId={currentPropertyDirectory?.id}
                splitFee={currentCompany?.currentUser?.currentCompany?.separateBrynsonFeeIlsSpend}
              />
            </div>
            <div className="flex h-[75dvh] flex-col">
              <div className="mb-8 w-64">
                <Selectbox
                  options={archivedPropertyDirectories
                    .sort((a, b) => {
                      const dateA = new Date(`${a.month}-01-${a.year}`);
                      const dateB = new Date(`${b.month}-01-${b.year}`);
                      return dateB - dateA;
                    })
                    .map((i) => ({ value: i.id, label: `${i.month}-${i.year}` }))}
                  onChange={(value) => {
                    setSelectedPropertyDirectory(value)
                  }}
                  id="property-directory-historic-property-directory"
                />
              </div>
              {archivedPropertyDirectories
                .filter((directory) => directory.id === selectedPropertyDirectory)
                .map((propertyDirectory, index) => (
                  <PropertiesTable
                    key="index"
                    properties={propertyDirectory.propertyDirectoryInfo}
                    propertyDirectoryId={propertyDirectory.id}
                  />
                ))}
            </div>
          </BrynsonTabs>
        </div>
      </TabContext.Provider>
      
    </PropertiesDirectoryProvider>
  )
}
