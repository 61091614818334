import React from "react"
import { ResponsiveBar } from '@nivo/bar'

export default function fanEngagement({data = {}}) {
  const ages = ["13-17", "18-24", "25-34", "35-44", "45-54", "55-64", "65+"]
  
  const womenData = []
  ages.forEach(age => {
    womenData.push({
      ageGroup: age,
      fans: ((data.women.fans[age] / data.women.fans.total) * 100).toFixed(2),
      engaged: ((data.women.engaged[age] / data.women.engaged.total) * 100).toFixed(2)
    })
  });

  const menData = []
  ages.forEach(age => {
    menData.push({
      ageGroup: age,
      fans: ((data.men.fans[age] / data.men.fans.total) * 100).toFixed(2),
      engaged: ((data.men.engaged[age] / data.men.engaged.total) * 100).toFixed(2)
    })
  });
  
  const Chart = ({data, reverse = false}) => {
    const colors = { 'fans': '#6E6566', 'engaged': '#63D6A7' }
    const getColor = bar => colors[bar.id]
    return (
      <ResponsiveBar
        data={data}
        keys={['fans', 'engaged']}
        colors={getColor}
        indexBy={'ageGroup'}
        margin={{top: 10, bottom: 10, left: 130, right: 60}}
        groupMode="grouped"
        valueFormat={value => `${value}%`}
        axisLeft={null}
        enableGridY={false}
        reverse={reverse}
        legends={[{
          dataFrom: 'keys',
          anchor: 'top-left',
          direction: 'column',
          translateX: -120,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          symbolSize: 20,
        }]}
        >
        </ResponsiveBar>
    )
  }

  return (
    <div className="card rounded p-4">
      <div className="font-medium mb-4">Fan & Engagement Demographics</div>
      <div className="font-medium text-xs">Women</div>
      <div className="h-[100px] mb-4">
        <Chart data={womenData}/>
      </div>
      <div className="font-medium text-xs">Men</div>
      <div className="h-[100px]">
        <Chart data={menData} reverse={true}/>
      </div>
    </div>
  )
}