import React from "react"
import numeral from "numeral"
import { ArrowTrendingUpIcon } from '@heroicons/react/24/solid';
import { ArrowTrendingDownIcon } from '@heroicons/react/24/solid';
import { FaFacebookF, FaLinkedinIn, FaInstagram, FaTiktok, FaGoogle } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

export default function pageMetrics({metrics = {}}) {
  const PerformanceIndicator = ({now, last}) => {
		if (now > last) {
			return <ArrowTrendingUpIcon className="ml-2 h-5 w-5 text-green-400" />;
		}

		if (now < last) {
			return <ArrowTrendingDownIcon className="ml-2 h-5 w-5 text-red-400" />;
		}

		return null
	}

  const abbreviateNumber = number => numeral(number).format('0,00a')

  return (
    <div className="card rounded p-4">
      <div className="grid grid-cols-6 gap-4">
        <div>
          <div className="font-semibold text-xl text-center flex justify-center">
            <FaFacebookF size={32} />
            <span className="ml-2 font-normal">
              { abbreviateNumber(metrics.facebook.likes.now) }
            </span>
          </div>
          <div className="text-center font-medium text-lg">page likes</div>
          <div className="text-center flex justify-center text-sm gap-2 p-2">
            <div>
              <div>Reach</div>
              <div className="inline-flex">
                {abbreviateNumber(metrics.facebook.reach.now)}
                <PerformanceIndicator
                  last={metrics.facebook.reach.last}
                  now={metrics.facebook.reach.now}
                />
              </div>
            </div>
            <div>
              <div>Engaged</div>
              <div className="inline-flex">
                {abbreviateNumber(metrics.facebook.engaged.now)}
                <PerformanceIndicator
                  last={metrics.facebook.engaged.last}
                  now={metrics.facebook.engaged.now}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="font-semibold text-xl text-center flex justify-center">
            <FaXTwitter size={32} />
            <span className="ml-2 font-normal">{ abbreviateNumber(metrics.twitter.followers.now) }</span>
          </div>
          <div className="text-center font-medium text-lg">followers</div>
          <div className="text-center flex justify-center text-sm gap-2 p-2">
            <div>
              <div>Reach</div>
              <div className="inline-flex">
                {abbreviateNumber(metrics.twitter.reach.now)}
                <PerformanceIndicator
                  last={metrics.twitter.reach.last}
                  now={metrics.twitter.reach.now}
                />
              </div>
            </div>
            <div>
              <div>Engaged</div>
              <div className="inline-flex">
                {abbreviateNumber(metrics.twitter.engaged.now)}
                <PerformanceIndicator
                  last={metrics.twitter.engaged.last}
                  now={metrics.twitter.engaged.now}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="font-semibold text-xl text-center flex justify-center">
            <FaGoogle size={32}/>
            <span className="ml-2 font-normal">{ abbreviateNumber(metrics.gmb.followers.now) }</span>
          </div>
          <div className="text-center font-medium text-lg">followers</div>
          <div className="text-center flex justify-center text-sm gap-2 p-2">
            <div>
              <div>Maps Impressions</div>
              <div className="inline-flex">
                {abbreviateNumber(metrics.gmb.views_maps.now)}
                <PerformanceIndicator
                  last={metrics.gmb.views_maps.last}
                  now={metrics.gmb.views_maps.now}
                />
              </div>
            </div>
            <div>
              <div>Search Impressions</div>
              <div className="inline-flex">
                {abbreviateNumber(metrics.gmb.views_search.now)}
                <PerformanceIndicator
                  last={metrics.gmb.views_search.last}
                  now={metrics.gmb.views_search.now}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="font-semibold text-xl text-center flex justify-center">
            <FaLinkedinIn size={32} />
            <span className="ml-2 font-normal">{ abbreviateNumber(metrics.linkedin.followers.now) }</span>
          </div>
          <div className="text-center font-medium text-lg">followers</div>
          <div className="text-center flex justify-center text-sm gap-2 p-2">
            <div>
              <div>Post Likes</div>
              <div className="inline-flex">
                {abbreviateNumber(metrics.linkedin.likes.now)}
                <PerformanceIndicator
                  last={metrics.linkedin.likes.last}
                  now={metrics.linkedin.likes.now}
                />
              </div>
            </div>
            <div>
              <div>Comments</div>
              <div className="inline-flex">
                {abbreviateNumber(metrics.linkedin.comments.now)}
                <PerformanceIndicator
                  last={metrics.linkedin.comments.last}
                  now={metrics.linkedin.comments.now}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="font-semibold text-xl text-center flex justify-center">
            <FaInstagram size={32} />
            <span className="ml-2 font-normal">{ abbreviateNumber(metrics.instagram.followers.now) }</span>
          </div>
          <div className="text-center font-medium text-lg">followers</div>
          <div className="text-center flex justify-center text-sm gap-2 p-2">
            <div>
              <div>Post Likes</div>
              <div className="inline-flex">
                {abbreviateNumber(metrics.instagram.likes.now)}
                <PerformanceIndicator
                  last={metrics.instagram.likes.last}
                  now={metrics.instagram.likes.now}
                />
              </div>
            </div>
            <div>
              <div>Comments</div>
              <div className="inline-flex">
                {abbreviateNumber(metrics.instagram.comments.now)}
                <PerformanceIndicator
                  last={metrics.instagram.comments.last}
                  now={metrics.instagram.comments.now}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="font-semibold text-xl text-center flex justify-center">
            <FaTiktok size={32} />
            <span className="ml-2 font-normal">{ abbreviateNumber(metrics.tiktok.followers.now) }</span>
          </div>
          <div className="text-center font-medium text-lg">followers</div>
          <div className="text-center flex justify-center text-sm gap-2 p-2">
            <div>
              <div>Likes</div>
              <div className="inline-flex">
                {abbreviateNumber(metrics.tiktok.likes.now)}
                <PerformanceIndicator
                  last={metrics.tiktok.likes.last}
                  now={metrics.tiktok.likes.now}
                />
              </div>
            </div>
            <div>
              <div>Video Views</div>
              <div className="inline-flex">
                {abbreviateNumber(metrics.tiktok.video_views.now)}
                <PerformanceIndicator
                  last={metrics.tiktok.video_views.last}
                  now={metrics.tiktok.video_views.now}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}