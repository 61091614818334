import React, { useMemo, useContext, useState } from "react";
import { useQuery } from 'urql';
import BrynsonSquareButton from "../../shared/BrynsonSquareButton";
import { GlobalContext } from "../../context/GlobalContext";
import CreateRateModal from "./CreateRateModal";

const RATE_STRUCTURES_QUERY = `
  query {
    rateStructures {
      id
      ilsType
      rateType
      effectiveFrom
      effectiveTo
      minUnits
      maxUnits
      contractTermMonths
      packageName
      rate
      additionalAttributes
    }
  }
`;

const TermsAndConditions = ({ terms }) => {
  if (!terms || terms.length === 0) return null;

  return (
    <div className="mt-4 p-4 bg-gray-50 rounded-lg">
      <h3 className="font-semibold mb-3">Terms & Conditions</h3>
      <ul className="space-y-2">
        {terms.map((term, index) => (
          <li key={index} className="flex items-start">
            <span className="mr-2 text-gray-600">⦿</span>
            <span className="text-gray-700">{term}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default function RateStructures() {
  const [showCreateRate, setShowCreateRate] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [rateToEdit, setRateToEdit] = useState(null); // Track the rate being edited

  const [{ data: rateStructuresData }] = useQuery({ query: RATE_STRUCTURES_QUERY });
  const { currentUserData } = useContext(GlobalContext);

  console.log(rateStructuresData)
  const showActions = currentUserData?.currentUser?.email?.endsWith('@brynson.com');

  const groupedRates = useMemo(() => {
    if (!rateStructuresData?.rateStructures) return {};
    return rateStructuresData.rateStructures.reduce((acc, rate) => {
      const key = `${rate.ilsType}-${rate.rateType}-${rate.contractTermMonths}`;
      if (!acc[key]) acc[key] = [];
      acc[key].push(rate);
      return acc;
    }, {});
  }, [rateStructuresData]);

  const renderRateTable = (rates) => {
    const packages = [...new Set(rates.map(r => r.packageName))];
    const unitRanges = [...new Set(rates.map(r => `${r.minUnits}-${r.maxUnits}`))].sort((a, b) => {
      const [aMin] = a.split('-').map(Number);
      const [bMin] = b.split('-').map(Number);
      return aMin - bMin;
    });

    const hideUnitCountColumn = unitRanges.every(range => (range === 'null-null' || range === '1-null'));

    return (
      <>
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              {!hideUnitCountColumn && (
                <th className="border-b border-r px-4 py-2">UnitCount</th>
              )}
              {packages.map(pkg => (
                <th key={pkg} className="border-b border-r px-4 py-2">{pkg}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {unitRanges.map(range => {
              const [minUnits, maxUnits] = range.split('-');
              const displayRange = minUnits === 'null' && maxUnits === 'null'
                ? null
                : maxUnits === 'null'
                ? `${minUnits}+`
                : `${minUnits}-${maxUnits}`;

              return (
                <tr key={range}>
                  {displayRange && !hideUnitCountColumn && (
                    <td className="border-b border-r px-4 py-2">{displayRange}</td>
                  )}
                  {packages.map(pkg => {
                    const rate = rates.find(r => r.packageName === pkg && `${r.minUnits}-${r.maxUnits}` === range);
                    return (
                      <td
                        key={`${range}-${pkg}`}
                        className="border-b border-r px-4 py-2 cursor-pointer"
                        onClick={() => {
                          setRateToEdit(rate); // Set the rate to edit
                          setShowCreateRate(true); // Open the modal
                        }}
                      >
                        {rate ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(rate.rate) : '-'}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  return (
    <div className="w-full p-8">
      <h1 className="font-semibold text-xl mb-6">ILS Rates</h1>
      {showActions &&
        <div className="text-right mb-4">
          <BrynsonSquareButton
            label="Create Rate"
            fullWidth={false}
            onClick={() => {
              setRateToEdit(null); // Reset rateToEdit when creating a new rate
              setShowCreateRate(true);
            }}
          />
        </div>
      }
      {Object.entries(groupedRates).map(([key, rates]) => {
        const [ilsType, rateType, contractTerm] = key.split('-');

        // Find terms & conditions from the first rate in the group
        const termsAndConditions = rates[0]?.additionalAttributes?.terms_and_conditions;

        return (
          <div key={key} className="mb-8">
            <h2 className="text-md font-semibold mb-4">
              {`${ilsType} ${rateType} Rates${contractTerm != 'null' ? ` (${contractTerm} Months)` : ''}`}
            </h2>
            {renderRateTable(rates)}
            <TermsAndConditions terms={termsAndConditions} />
          </div>
        );
      })}
      {showCreateRate && (
        <CreateRateModal
          showCreateRate={showCreateRate}
          rateToEdit={rateToEdit} // Pass the rate to be edited or null for a new rate
          close={(rateAdded) => {
            setShowCreateRate(false);
            if (rateAdded) {
              setShowAlert(true);
              setAlertContent("Rate added successfully");
              setTimeout(() => {
                setShowAlert(false);
              }, 10000);
            }
          }}
        />
      )}
    </div>
  );
}
