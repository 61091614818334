export const CURRENT_USER_QUERY = `
  query {
    currentUser {
      id
      name
      email
      photoUrl
      birthday
      birthdayMonth
      currentCompany {
        id
        about
        directoryCount
        hasSociKey
        propertyDirectoryColumns {
          accessor
          Header
          type
        }
        propertyDirectoryInfos {
          id
        }
        iframeIntegrations {
          id
          name
          description
          category
        }
      }
    }
  }
`
export const UPDATE_CURRENT_USER_MUTATION = `
  mutation UpdateCurrentUserMutation($currentCompanyId:Int, $name:String, $email:String, $filestackPhoto:String, $birthday:Int, $birthdayMonth:Int) {
    updateCurrentUserMutation(currentCompanyId:$currentCompanyId, name:$name, email:$email, filestackPhoto:$filestackPhoto, birthday:$birthday, birthdayMonth:$birthdayMonth) {
      result
    }
  }
`
