import React, {useContext, useEffect, useState} from "react"
import { useQuery } from "urql"
import { GlobalContext } from "../../context/GlobalContext"
import Loading from "../../shared/Loading"
import PageMetrics from "./PageMetrics";
import ContentStatus from "./ContentStatus";
import FanEngagement from "./FanEngagement";
import EngagementSentiment from "./EngagementSentiment"
import EngagementStatus from "./EngagementStatus"
import EngagementActivity from "./EngagementActivity"
import ReviewSentiment from "./ReviewSentiment"
import ReviewsByNetwork from "./ReviewsByNetwork"
import OpenReviews from "./OpenReviews"

const SOCIS_QUERY = `
  query {
		socis {
			engagementActivity
			buckets
			sentiments {
				total
				positive
				neutral
				negative
				averageSentiment
			}
			pageMetrics {
				facebook
				twitter
				gmb
				linkedin
				instagram
				tiktok
			}
			reviewsSentiment {
				averageRating
				positiveCount
				neutralCount
				negativeCount
			}
			reviewsNetwork {
				apartments
				aptguide
				aptratings
				facebook
				gmb
				yelp
			}
			fanEngagement {
				men
				women
			}
			monitoringSummary {
				late
				open
				total
			}
			tasksSummary {
				failed
				pending
				rejected
				sent
				total
				unpublished
			}
		}
	}
`

export default function Soci() {
	const { currentCompanyData } = useContext(GlobalContext)

	const [{ data: socisData }, socisReload] = useQuery({
    query: SOCIS_QUERY,
    requestPolicy: "network-only"
  })

	const isLoading = currentCompanyData && socisData

	return (
		<div className="p-8">
			<h1 className="font-semibold text-xl">Soci</h1>
			{
				isLoading ? (
					<>
						{
							currentCompanyData.currentUser.currentCompany.hasSociKey ? (
								<>
									<p className="text-lg font-medium mb-4">Integrate with your marketing delivery provider</p>
									<PageMetrics metrics={socisData.socis.pageMetrics}/>
									<div className="grid grid-cols-[3fr_7fr] gap-4 mt-4">
										<ContentStatus data={socisData.socis.tasksSummary} />
										<FanEngagement data={socisData.socis.fanEngagement} />
									</div>
									<div className="grid grid-cols-[3fr_7fr] gap-4 mt-4">
										<div>
											<EngagementSentiment data={socisData.socis.sentiments}/>
											<div className="mb-4"></div>
											<EngagementStatus data={socisData.socis.monitoringSummary} />
										</div>
										<EngagementActivity data={socisData.socis.engagementActivity} />
									</div>
									<div className="grid grid-cols-2 gap-4 mt-4">
										<ReviewSentiment data={socisData.socis.reviewsSentiment} />
										<ReviewsByNetwork data={socisData.socis.reviewsNetwork} />
									</div>
									<OpenReviews data={socisData.socis.buckets}/>
								</>
							) : (
								<p className="text-lg font-medium">No integrate with your marketing delivery provider</p>		
							)
						}
					</>
				) : (
					<Loading></Loading>
				)
			}
			
		</div>
	)
}