import React from "react"
import { FaComments, FaRegBell } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";

export default function engagementStatus({data}) {
  return (
    <div className="card rounded p-4">
      <div className="font-medium mb-4">Engagement Status</div>
      <div className="flex justify-center items-center">
        <FaComments className="text-3xl"/>
        <div className="ml-4 text-center">
          <div className="text-5xl">{data.total}</div>
          <div className="text-sm">Engagements</div>
        </div>
      </div>
      <div className="border border-gray my-4"></div>
      <div className="flex justify-center items-center">
        <div className="flex flex-col justify-center items-center">
          <div className="flex text-green-400 items-center">
            <MdOutlineMail className="text-2xl"/>
            <span className="ml-2 text-4xl">{data.open}</span>
          </div>
          <div>Open</div>
        </div>
        <div className="flex flex-col justify-center items-center ml-8">
          <div className="flex text-orange-400 items-center">
            <FaRegBell className="text-2xl"/>
            <span className="ml-2 text-4xl">{data.late}</span>
          </div>
          <div>Past Due</div>
        </div>
      </div>
    </div>
  )
}