import React, { useState, useContext, useEffect } from "react"
import { GlobalContext } from "../../context/GlobalContext"
import BrynsonTabs from "../../shared/BrynsonTabs"
import ILSImportButtons from "../../shared/ILSImportButtons"
import PerformanceDashboard from "./PerformanceDashboard"
import DailyView from './DailyView'
import MonthlyView from './MonthlyView'
import BrynsonEmbeddedDocuments from "../../shared/BrynsonEmbeddedDocuments"
import IlsLeadsTable from "./IlsLeadsTable"
import IlsLeads from "./IlsLeads"
const comingsoon = require("../../../../../public/samples/coming_soon.gif")


export default function Conversions() {
  const { currentUserData, currentCompanyData } = useContext(GlobalContext)
  const about = currentCompanyData?.currentUser?.currentCompany?.about || ""
  const isBrynsonUser = currentUserData?.currentUser?.email?.endsWith('@brynson.com');
  const csrfToken = document.querySelector("meta[name='csrf-token']")?.content || ""
  const [selectedTab, setSelectedTab] = useState("zillow")

  const tabs = [

    { value: "apartments_com", label: "Apartments.com" },
    { value: "apartments_list", label: "Apartment List" },
    { value: "rent_com", label: "Rent.com" },
    { value: "zillow", label: "Zillow" },
    { value: "pms_reported", label: "PMS Reported" },
    { value: "ils_to_pms", label: "ILS to PMS Lease Match" }
  ]

  const handleCSVSuccess = (data) => console.log("CSV Import Successful:", data)
  const handleCSVError = (error) => console.error("CSV Import Error:", error)
  const handleTabSelected = (tabIndex) => setSelectedTab(tabs[tabIndex].value)

  const ALLOWED_COMPANIES = [
    "Olympus Property",
    "PMC Demo",
    "Prime Management LLC",
    "Living Residential",
    "Chelsea Management"
  ];
  if (ALLOWED_COMPANIES.includes(about)) {
    return (
      <div className="p-8">
        <div className="flex justify-between">
          <h1 className="text-xl font-semibold">ILS Performance Data</h1>
          {isBrynsonUser && selectedTab !== "pms_reported" && (
            <ILSImportButtons
              csrfToken={csrfToken}
              onSuccess={handleCSVSuccess}
              onError={handleCSVError}
              source={selectedTab}
            />
          )}
        </div>
        <div className="mt-8">
          <BrynsonTabs tabs={tabs} onSelected={handleTabSelected}>
            {tabs.map((tab, index) => (
              <div key={index}>
                {(tab.value === "pms_reported" || tab.value === "ils_to_pms") ? (
                  <>
                  <div className="p-8">
                    <h2 className="text-lg font-semibold mb-2">Coming Soon</h2>
                    {tab.value === "pms_reported" ? (
                      <p className="text-lg font-medium">Brynsights™ will pull the ILS Performance data from your PMS (as well as direct from the ILS) and display it here.</p>
                    ) : (
                      <p className="text-lg font-medium">Brynsights™ compare the leases reported from your ILS vs. your PMS so you can reconcile the source and have accurate attribution.</p>
                    )}

                    <div className="flex justify-center align-center mt-8">
                      <img src={comingsoon} />
                    </div>
                  </div>
                  {/* <div>
                    <div className="mt-8">
                      <DailyView />
                    </div>
                    <div className="mt-8">
                      <MonthlyView />
                    </div>
                  </div>*/}
                  </>
                ) : (
                  <div>
                    <div className="mt-8">
                      <PerformanceDashboard source={tab.value} />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </BrynsonTabs>
        </div>
      </div>
    )
  } else {
    return (
      <div className="p-8">
        <h1 className="text-xl font-semibold mb-2">ILS Performance Data - Coming Soon</h1>
        <p className="text-lg font-medium">At Brynsights, we're constantly developing new features. This is on the way!</p>
        <div className="flex justify-center align-center mt-8">
          <img src={comingsoon} />
        </div>
      </div>
    )
  }
}