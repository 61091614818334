import React from "react"
import { FaCalendarAlt } from "react-icons/fa";
import { FaRegClock, FaExclamation, FaQuestion } from "react-icons/fa6";

export default function contentStatus({data = {}}) {  
  return (
    <div className="card rounded p-4">
      <div className="font-medium mb-4">Content Status</div>
      <div className="flex justify-center items-center">
        <FaCalendarAlt className="text-3xl"/>
        <div className="ml-4 text-center">
          <div className="text-5xl">{data.unpublished}</div>
          <div className="text-sm">Scheduled</div>
        </div>
      </div>
      <div className="border border-gray my-4"></div>
      <div className="flex justify-center items-center">
        <div className="flex flex-col justify-center items-center">
          <div className="flex text-green-400 items-center">
            <FaRegClock className="text-2xl"/>
            <span className="ml-2 text-4xl">{data.pending}</span>
          </div>
          <div>Pending</div>
        </div>
        <div className="flex flex-col justify-center items-center mx-8">
          <div className="flex text-orange-400 items-center">
            <FaExclamation className="text-2xl"/>
            <span className="ml-2 text-4xl">{data.rejected}</span>
          </div>
          <div>Rejected</div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <div className="flex text-red-400 items-center">
            <FaQuestion className="text-2xl"/>
            <span className="ml-2 text-4xl">{data.failed}</span>
          </div>
          <div>Failed</div>
        </div>
      </div>
    </div>
  )
}